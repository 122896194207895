<template>
    <v-card max-width="600px" class="mx-auto">
        <v-stepper v-model="step">
            <v-stepper-header>
                <v-stepper-step step="1" :complete="step > 1">身分驗證</v-stepper-step>
                <v-stepper-step step="2" :complete="step > 2">密碼重設</v-stepper-step>
            <v-stepper-step step="3">重設成功</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1" :complete="step > 1">
                    <v-card>
                        <v-card-text style="
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 22px;
                            text-align: center;"
                        >
                            請輸入註冊所使用的電子郵件，我們將會寄送驗證碼到您的信箱
                        </v-card-text>
                        <v-text-field
                            v-model="email"
                            label="電子郵件"
                            outlined
                        >
                        </v-text-field>
                        <div class="d-flex">
                            <v-text-field
                                v-model="code"
                                label="驗證碼"
                                outlined
                                class="mr-7"
                            >
                                <template #append class="special">
                                    <v-btn
                                        tonal
                                        color="secondary"
                                        :disabled="flag"
                                        @click="getAuthCode"
                                    >
                                        {{msg}}
                                    </v-btn>
                                </template>
                            </v-text-field>
                            <v-btn
                                dark
                                color="primary"
                                class="mt-2"
                                @click="confirmCode"
                            >
                                確認
                            </v-btn>
                        </div>
                        <v-alert
                            :value="alertPlace == 'getCode'"
                            :type="alertType"
                            dense
                            outlined
                            class="mt-3"
                        >
                            {{alertMsg}}
                        </v-alert>
                    </v-card>
                </v-stepper-content>
                <v-stepper-content step="2" :complete="step > 2">
                    <v-card>
                        <v-form
                            ref="passwordForm"
                            v-model="passwordForm"
                            lazy-validation
                            class="pa-5"
                            @submit.prevent="changePassword"
                        >
                            <v-text-field
                                v-model="password.newPassword"
                                label="新密碼"
                                :append-icon="showPassword.newPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword.newPassword ? 'text' : 'password'"
                                :rules="passwordRules"
                                @click:append="showPassword.newPassword = !showPassword.newPassword"
                                outlined
                            >
                            </v-text-field>
                            <v-text-field
                                v-model="password.confirm"
                                label="確認密碼"
                                :append-icon="showPassword.confirm ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword.confirm ? 'text' : 'password'"
                                :rules="[v => !!v && v===password.newPassword || '密碼不相同']"
                                @click:append="showPassword.confirm = !showPassword.confirm"
                                outlined
                            >
                            </v-text-field>
                            <v-btn
                                dark
                                color="primary"
                                block
                                type="submit"
                                @click.prevent="changePassword"
                            >
                                確認修改
                            </v-btn>
                        </v-form>
                        <v-alert
                            :value="alertPlace == 'changePassword'"
                            :type="alertType"
                            dense
                            outlined
                            class="mt-3"
                        >
                            {{alertMsg}}
                        </v-alert>
                    </v-card>
                </v-stepper-content>

                <v-stepper-content step="3" :complete="step > 2">
                    <v-card class="d-flex flex-column align-center">
                        <v-card-text style="
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 22px;
                            text-align: center;"
                        >
                            密碼更改完成，請按下方按鈕回到首頁登入
                        </v-card-text>
                        <v-btn
                            dark
                            color="primary"
                            @click="$router.push('/')"
                            class="mb-2"
                        >
                            回到首頁
                        </v-btn>
                    </v-card>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-card>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ForgetPw',

  data: () => ({
    alertPlace: '',
    alertType: null,
    alertMsg: '',

    flag: false,
    step: 1,
    msg: '發送驗證碼',
    showPassword: {
      newPassword: false,
      confirm: false
    },
    passwordForm: false,

    email: '',
    code: '',
    password: {
      oldPassword: '',
      newPassword: ''
    },

    passwordRules: [
      v => !!v || 'Password is required',
      // v => (v && v.length >= 8) || 'Password must be longer than 8 characters'
      v => /^(?=.*\d)(?=.*[^a-zA-Z0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(v) || '需含有至少大小寫英文字母、數字和特殊字元，長度8位以上'
    ]
  }),
  methods: {
    getAuthCode () {
      const config = {
        url: '/api/loginApp/sendcode',
        method: 'put',

        data: {
          email: this.email
        }
      }
      axios(config)
        .then((res) => {
          this.alertPlace = 'getCode'
          this.alertType = 'success'
          this.alertMsg = res.data.message

          const _this = this
          this.flag = true
          var time = 60
          var timer = null
          this.msg = time + '秒後可再次發送'
          timer = setInterval(function () {
            if (time === 0) {
              _this.msg = '重新發送'
              _this.flag = false
              clearInterval(timer)
            } else {
              time--
              _this.msg = time + '秒後可再次發送'
            }
          }, 1000)
        })
        .catch((err) => {
          this.alertPlace = 'getCode'
          this.alertType = 'error'
          this.alertMsg = err.response.data.message
        })
    },
    confirmCode () {
      const config = {
        url: '/api/loginApp/confirm',
        method: 'put',

        data: {
          email: this.email,
          code: this.code
        }
      }
      axios(config)
        .then((res) => {
          this.alertPlace = 'getCode'
          this.alertType = 'success'
          this.alertMsg = res.data.message
          this.step = 2
        })
        .catch((err) => {
          this.alertPlace = 'getCode'
          this.alertType = 'error'
          this.alertMsg = err.response.data.message
        })
    },
    changePassword () {
      if (this.$refs.passwordForm.validate()) {
        const config = {
          url: '/api/loginApp/forgetChange',
          method: 'put',

          data: {
            email: this.email,
            newPassword: this.password.newPassword
          }
        }
        axios(config)
          .then((res) => {
            this.alertPlace = 'changePassword'
            this.alertType = 'success'
            this.alertMsg = res.data.message
            this.step = 3
          })
          .catch((err) => {
            this.alertPlace = 'changePassword'
            this.alertType = 'error'
            this.alertMsg = err.response.data.message
          })
      }
    }
  }
}
</script>
